import React from 'react'
import { insertEvent } from './util/trackingSiteBehavior'

const AppNavigate2 = () => {
  return (
    <>
     <div class="container">
 
         
     {/* <!-- SECTION TITLE -->	 */}
     <div class="row justify-content-center">	
         <div class="col-md-8">
             <div class="section-title text-center mb-80">	
 
                 {/* <!-- Title -->	 */}
                 <h4 class="h2-title">We Offer Anonymous Therapy, Psychological Prescriptions, and other Self-help Solutions</h4>	
 
                 {/* <!-- Text --> */}
                 <p class="p-lg">Using reason and logic we help you  dismantle beliefs, break free from societal constructs and definitions that unknowingly cause pain, and think better. Scroll down to learn more.</p>
                     
             </div>	
         </div>
     </div>
 
 {/* 
     <!-- FEATURES WRAPPER --> */}
     <div class="fbox-wrapper text-center">
         <div class="row row-cols-1 row-cols-md-3">
 
 
             {/* <!-- FEATURE BOX #1 --> */}
              <div class="col">
                  <div class="fbox-6 fb-1 wow animate__animated animate__fadeInUp animate__delay-1">
 
                    
 
                     {/* <!-- Text --> */}
                     <div class="fbox-txt">
                         <h6 class="h6-xl">Use Our Pyschological Prescriptions </h6>
                         <p><strong>We have prescriptions for:</strong> Social anxiety (free), physical anxiety, low self-esteem, self-consciousness, low self-worth, low self-confidence, feeling inadequate, feeling not good enough, and feeling inferior
                         </p>
                         {/* <a style={{'margin-right': '5px'}}href={``} class="btn  btn-md r-36 btn--tra-black hover--tra-black" onClick={() =>
           {
             insertEvent('click',{'button':'therapy',"objectName":`conditions`})
            
        }}>Get Therapy</a>  */}
 
 <a href={`/conditions`} class="btn mt-1 r-36 btn--black hover--tra-black" onClick={() =>
           {
             insertEvent('click',{'button':'see conditions'})
          
        }}>See our prescriptions</a> 
                     </div>
 
                  </div>
              </div>	
              {/* <!-- END FEATURE BOX #1 -->	 */}
 
 
              {/* <!-- FEATURE BOX #2 --> */}
              <div class="col">
                  <div class="fbox-6 fb-2 wow animate__animated animate__fadeInUp animate__delay-2">
 
                     
 
                     {/* <!-- Text --> */}
                     <div class="fbox-txt">
                         <h6 class="h6-xl">Dismantle a belief</h6>
                         <p><strong>Beliefs we cover:</strong> Something is wrong with me, I'm flawed, Some people are inferior...We will be releasing more dismantle-belief solutions in the future. <a class="text-danger hover--tra-black" href='/createaccount'>Create an account</a> to be notified of new and early releases.
                         </p>
 
                         <a style={{'margin-right': '5px'}}href={``} class="btn mt-1 btn-md r-36 btn--tra-black hover--tra-black" onClick={() =>
           {
             insertEvent('click',{'button':'dismantle belief',"objectName":`beliefs`})
            
        }}>Dismantle a Belief</a> 
                     </div>
 
                  </div>
              </div>	
              {/* <!-- END FEATURE BOX #2 -->	 */}
 
 
              {/* <!-- FEATURE BOX #3 --> */}
              <div class="col">
                  <div class="fbox-6 fb-3 wow animate__animated animate__fadeInUp animate__delay-3">
 
                     
 
                     {/* <!-- Text --> */}
                     <div class="fbox-txt">
                         <h6 class="h6-xl">Get Therapy</h6>
                         <p>Although we think we can help with almost anything, we focus on helping people understand and eliminate negative emotions and behaviors they want to change or get rid of.
                             <br>
                             </br>
                             The results are better performance at work and life overall.
                         </p>
                         <a style={{'margin-right': '5px'}}href={``} class="btn mt-1  btn-md r-36 btn--tra-black hover--tra-black" onClick={() =>
           {
             insertEvent('click',{'button':'dismantle belief',"objectName":`beliefs`})
            
        }}>Learn More</a> 
                     </div>
 
                  </div>
              </div>
                {/* <!-- END FEATURE BOX #3 -->	 */}
 
                  {/* <!-- FEATURE BOX #4 --> */}
              <div class="col">
                  <div class="fbox-6 fb-4 wow animate__animated animate__fadeInUp animate__delay-3">
 
                     
 
                     {/* <!-- Text --> */}
                     <div class="fbox-txt">
                         <h6 class="h6-xl">Break free from worldy constructs </h6>
                         <p>Constructs and definitions that unconsciously cause unhappiness and negative emotions.
                         </p>
 
                         <a style={{'margin-right': '5px'}}href={``} class="btn mt-1  btn-md r-36 btn--tra-black hover--tra-black" onClick={() =>
           {
             insertEvent('click',{'button':'loaded words',"objectName":`loadedwords`})
            
        }}>Search for a loaded term</a> 
                     </div>
 
                  </div>
              </div>
                {/* <!-- END FEATURE BOX #4 -->	 */}
 
                 {/* <!-- FEATURE BOX #5 --> */}
              <div class="col">
                  <div class="fbox-6 fb-5 wow animate__animated animate__fadeInUp animate__delay-3">
 
                     
 
                     {/* <!-- Text --> */}
                     <div class="fbox-txt">
                         <h6 class="h6-xl">Access critical insights</h6>
                         <p>That will help you see and think better.
                         </p>
                         <a style={{'margin-right': '5px'}}href={``} class="btn mt-1 btn-md r-36 btn--tra-black hover--black" onClick={() =>
           {
             insertEvent('click',{'button':'insights',"objectName":`insights`})
            
        }}>Get Clarity</a> 
                     </div>
                     
 
                  </div>
              </div>
                {/* <!-- END FEATURE BOX #5 -->	 */}
 
 
                 
         </div>  
         {/* <!-- End row -->   */}
     </div>	
     {/* <!-- END FEATURES WRAPPER --> */}

     {/* features section with icons */}
     <div class="container">


					{/* <!-- SECTION TITLE -->	 */}
					<div class="row justify-content-center">	
						<div class="col-md-9 col-lg-8">
							<div class="section-title text-center mb-80">	

								{/* <!-- Title -->	 */}
								<h2 class="h2-xl">Everything in One Place</h2>	

								{/* <!-- Text --> */}
								<p class="p-lg">Ligula risus auctor tempus magna feugiat lacinia laoreet luctus</p>
									
							</div>	
						</div>
					</div>


					{/* <!-- FEATURES WRAPPER --> */}
					<div class="fbox-wrapper">
						<div class="row row-cols-1 row-cols-md-2 rows-3">


							{/* <!-- FEATURE BOX #1 --> */}
		 					<div class="col">
		 						<div class="fbox-2 fb-1 wow animate__animated animate__fadeInUp animate__delay-1">

		 							{/* <!-- Icon --> */}
									<div class="fbox-ico-wrap ico-55">
										<div class="shape-ico color--theme-2">

											{/* <!-- Vector Icon --> */}
											<span class="flaticon-browser-2"></span>

											{/* <!-- Shape --> */}
											<svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
											  <path d="M66.1,-32.6C77.9,-17.7,74.4,11.6,60.8,35.7C47.2,59.7,23.6,78.5,2.8,76.9C-18,75.2,-35.9,53.2,-47.7,30.1C-59.5,7.1,-65.1,-16.9,-56.1,-30.1C-47.1,-43.4,-23.6,-46,1.8,-47C27.2,-48.1,54.3,-47.6,66.1,-32.6Z" transform="translate(100 100)" />
											</svg>

										</div>
									</div>	
                                    {/* <!-- End Icon --> */}

									{/* <!-- Text --> */}
									<div class="fbox-txt">
										<h5>Use Our Pyschological Prescriptions
                                        </h5>
										<p><strong>We have prescriptions for:</strong> Social anxiety (free), physical anxiety, low self-esteem, self-consciousness, low self-worth, low self-confidence, feeling inadequate, feeling not good enough, and feeling inferior
										</p>
                                        <a href={`/conditions`} class="btn mt-1 r-36 btn--black hover--tra-black" onClick={() =>
          {
            insertEvent('click',{'button':'see conditions'})
         
       }}>See our prescriptions</a> 

									</div>

		 						</div>
		 					</div>
                            	{/* <!-- END FEATURE BOX #1 -->	 */}


		 					{/* <!-- FEATURE BOX #2 --> */}
		 					<div class="col">
		 						<div class="fbox-2 fb-2 wow animate__animated animate__fadeInUp animate__delay-2">

		 							{/* <!-- Icon --> */}
									<div class="fbox-ico-wrap ico-55">
										<div class="shape-ico color--theme-2">

											{/* <!-- Vector Icon --> */}
											<span class="flaticon-color-palette"></span>

											{/* <!-- Shape --> */}
											<svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
											  <path d="M66.1,-32.6C77.9,-17.7,74.4,11.6,60.8,35.7C47.2,59.7,23.6,78.5,2.8,76.9C-18,75.2,-35.9,53.2,-47.7,30.1C-59.5,7.1,-65.1,-16.9,-56.1,-30.1C-47.1,-43.4,-23.6,-46,1.8,-47C27.2,-48.1,54.3,-47.6,66.1,-32.6Z" transform="translate(100 100)" />
											</svg>

										</div>
									</div>	
                                    {/* <!-- End Icon --> */}

									{/* <!-- Text --> */}
									<div class="fbox-txt">
										<h5>Dismantle a belief</h5>
										<p><strong>Beliefs we cover:</strong> Something is wrong with me, I'm flawed, Some people are inferior...We will be releasing more dismantle-belief solutions in the future. <a class="text-danger hover--tra-black" href='/createaccount'>Create an account</a> to be notified of new and early releases.
										</p>

                                        <a style={{'margin-right': '5px'}}href='/beliefsearch' class="btn mt-1 btn-md r-36 btn--tra-black hover--tra-black" onClick={() =>
          {
            insertEvent('click',{'button':'dismantle belief',"objectName":`beliefs`})
           
       }}>Dismantle a Belief</a> 
									</div>

		 						</div>
		 					</div>
                            	{/* <!-- END FEATURE BOX #2 -->	 */}


		 					{/* <!-- FEATURE BOX #3 --> */}
		 					<div class="col">
		 						<div class="fbox-2 fb-3 wow animate__animated animate__fadeInUp animate__delay-1">

		 							{/* <!-- Icon --> */}
									<div class="fbox-ico-wrap ico-55">
										<div class="shape-ico color--theme-2">

											{/* <!-- Vector Icon --> */}
											<span class="flaticon-ai"></span>

											{/* <!-- Shape --> */}
											<svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
											  <path d="M66.1,-32.6C77.9,-17.7,74.4,11.6,60.8,35.7C47.2,59.7,23.6,78.5,2.8,76.9C-18,75.2,-35.9,53.2,-47.7,30.1C-59.5,7.1,-65.1,-16.9,-56.1,-30.1C-47.1,-43.4,-23.6,-46,1.8,-47C27.2,-48.1,54.3,-47.6,66.1,-32.6Z" transform="translate(100 100)" />
											</svg>

										</div>
									</div>
                                    	{/* <!-- End Icon --> */}

									{/* <!-- Text --> */}
									<div class="fbox-txt">
										<h5>Get Therapy</h5>
										<p>Although we think we can help with almost anything, we focus on helping people understand and eliminate negative emotions and behaviors they want to change or get rid of.
										</p>
                                        <a style={{'margin-right': '5px'}}href={``} class="btn mt-1  btn-md r-36 btn--tra-black hover--tra-black" onClick={() =>
          {
            insertEvent('click',{'button':'dismantle belief',"objectName":`beliefs`})
           
       }}>Learn More</a> 
									</div>

		 						</div>
		 					</div>
                            	{/* <!-- END FEATURE BOX #3 -->	 */}


		 					{/* <!-- FEATURE BOX #4 --> */}
		 					<div class="col">
		 						<div class="fbox-2 fb-4 wow animate__animated animate__fadeInUp animate__delay-2">

		 							{/* <!-- Icon --> */}
									<div class="fbox-ico-wrap ico-55">
										<div class="shape-ico color--theme-2">

											{/* <!-- Vector Icon --> */}
											<span class="flaticon-folder-1"></span>

											{/* <!-- Shape --> */}
											<svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
											  <path d="M66.1,-32.6C77.9,-17.7,74.4,11.6,60.8,35.7C47.2,59.7,23.6,78.5,2.8,76.9C-18,75.2,-35.9,53.2,-47.7,30.1C-59.5,7.1,-65.1,-16.9,-56.1,-30.1C-47.1,-43.4,-23.6,-46,1.8,-47C27.2,-48.1,54.3,-47.6,66.1,-32.6Z" transform="translate(100 100)" />
											</svg>

										</div>
									</div>	
                                    {/* <!-- End Icon --> */}

									{/* <!-- Text --> */}
									<div class="fbox-txt">
										<h5>Break free from worldy constructs</h5>
										<p>PoConstructs and definitions that unconsciously cause unhappiness and negative emotions.
										</p>
                                        <a style={{'margin-right': '5px'}}href='loadedwordsearch' class="btn mt-1  btn-md r-36 btn--tra-black hover--tra-black" onClick={() =>
          {
            insertEvent('click',{'button':'loaded words',"objectName":`loadedwords`})
           
       }}>Search for a loaded term</a> 
									</div>

		 						</div>
		 					</div>	
                            {/* <!-- END FEATURE BOX #4 -->	 */}


		 					{/* <!-- FEATURE BOX #5 --> */}
		 					<div class="col">
		 						<div class="fbox-2 fb-5 wow animate__animated animate__fadeInUp animate__delay-1">

		 							{/* <!-- Icon --> */}
									<div class="fbox-ico-wrap ico-55">
										<div class="shape-ico color--theme-2">

											{/* <!-- Vector Icon --> */}
											<span class="flaticon-alter"></span>

											{/* <!-- Shape --> */}
											<svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
											  <path d="M66.1,-32.6C77.9,-17.7,74.4,11.6,60.8,35.7C47.2,59.7,23.6,78.5,2.8,76.9C-18,75.2,-35.9,53.2,-47.7,30.1C-59.5,7.1,-65.1,-16.9,-56.1,-30.1C-47.1,-43.4,-23.6,-46,1.8,-47C27.2,-48.1,54.3,-47.6,66.1,-32.6Z" transform="translate(100 100)" />
											</svg>

										</div>
									</div>	
                                    {/* <!-- End Icon --> */}

									{/* <!-- Text --> */}
									<div class="fbox-txt">
										<h5>Access critical insights</h5>
										<p>That will help you see and think better.
										</p>
                                        <a style={{'margin-right': '5px'}}href='/insights' class="btn mt-1 btn-md r-36 btn--tra-black hover--black" onClick={() =>
          {
            insertEvent('click',{'button':'insights',"objectName":`insights`})
           
       }}>Get Clarity</a> 
									</div>

		 						</div>
		 					</div>	
                            {/* <!-- END FEATURE BOX #5 -->	 */}


		 					


						</div> 
                         {/* <!-- End row -->   */}
					</div>	
                    {/* <!-- END FEATURES WRAPPER --> */}


				</div> 
 
     <h2 class="h2-xl text-center mt-3" ><em>Coming soon </em> </h2>	
                                <p>Our solution for overcoming an intelligence insecurity and more <a class="text-info hover--black" href="/insights/673e12e3e73b19869f085c48">real-life examples</a>  of how to uncover and eliminate a physical anxiety trigger.</p>
                                <br></br>
                                <p>To be notified of new free and premium solutions sign up  for free <a class="text-info hover--black" href="createaccount" >here.</a></p>
 
 
 </div>  

<div class="container">


					{/* <!-- SECTION TITLE -->	 */}
					<div class="row justify-content-center">	
						<div class="col-md-9 col-lg-8">
							<div class="section-title text-center mb-80">	

								{/* <!-- Title -->	 */}
								<h2 class="h2-xl">Everything in One Place</h2>	

								{/* <!-- Text --> */}
								<p class="p-lg">Ligula risus auctor tempus magna feugiat lacinia laoreet luctus</p>
									
							</div>	
						</div>
					</div>


					{/* <!-- FEATURES WRAPPER --> */}
					<div class="fbox-wrapper">
						<div class="row row-cols-1 row-cols-md-2 rows-3">


							{/* <!-- FEATURE BOX #1 --> */}
		 					<div class="col">
		 						<div class="fbox-2 fb-1 wow animate__animated animate__fadeInUp animate__delay-1">

		 							

									{/* <!-- Text --> */}
									<div class="fbox-txt">
										<h5>Use Our Pyschological Prescriptions
                                        </h5>
										<p><strong>We have prescriptions for:</strong> Social anxiety (free), physical anxiety, low self-esteem, self-consciousness, low self-worth, low self-confidence, feeling inadequate, feeling not good enough, and feeling inferior
										</p>
                                        <a href={`/conditions`} class="btn mt-1 r-36 btn--black hover--tra-black" onClick={() =>
          {
            insertEvent('click',{'button':'see conditions'})
         
       }}>See our prescriptions</a> 

									</div>

		 						</div>
		 					</div>
                            	{/* <!-- END FEATURE BOX #1 -->	 */}


		 					{/* <!-- FEATURE BOX #2 --> */}
		 					<div class="col">
		 						<div class="fbox-2 fb-2 wow animate__animated animate__fadeInUp animate__delay-2">

		 						

									{/* <!-- Text --> */}
									<div class="fbox-txt">
										<h5>Dismantle a belief</h5>
										<p><strong>Beliefs we cover:</strong> Something is wrong with me, I'm flawed, Some people are inferior...We will be releasing more dismantle-belief solutions in the future. <a class="text-danger hover--tra-black" href='/createaccount'>Create an account</a> to be notified of new and early releases.
										</p>

                                        <a style={{'margin-right': '5px'}}href='/beliefsearch' class="btn mt-1 btn-md r-36 btn--tra-black hover--tra-black" onClick={() =>
          {
            insertEvent('click',{'button':'dismantle belief',"objectName":`beliefs`})
           
       }}>Dismantle a Belief</a> 
									</div>

		 						</div>
		 					</div>
                            	{/* <!-- END FEATURE BOX #2 -->	 */}


		 					{/* <!-- FEATURE BOX #3 --> */}
		 					<div class="col">
		 						<div class="fbox-2 fb-3 wow animate__animated animate__fadeInUp animate__delay-1">

		 						

									{/* <!-- Text --> */}
									<div class="fbox-txt">
										<h5>Get Therapy</h5>
										<p>Although we think we can help with almost anything, we focus on helping people understand and eliminate negative emotions and behaviors they want to change or get rid of.
										</p>
                                        <a style={{'margin-right': '5px'}}href={``} class="btn mt-1  btn-md r-36 btn--tra-black hover--tra-black" onClick={() =>
          {
            insertEvent('click',{'button':'dismantle belief',"objectName":`beliefs`})
           
       }}>Learn More</a> 
									</div>

		 						</div>
		 					</div>
                            	{/* <!-- END FEATURE BOX #3 -->	 */}


		 					{/* <!-- FEATURE BOX #4 --> */}
		 					<div class="col">
		 						<div class="fbox-2 fb-4 wow animate__animated animate__fadeInUp animate__delay-2">

		 							

									{/* <!-- Text --> */}
									<div class="fbox-txt">
										<h5>Break free from worldy constructs</h5>
										<p>PoConstructs and definitions that unconsciously cause unhappiness and negative emotions.
										</p>
                                        <a style={{'margin-right': '5px'}}href='loadedwordsearch' class="btn mt-1  btn-md r-36 btn--tra-black hover--tra-black" onClick={() =>
          {
            insertEvent('click',{'button':'loaded words',"objectName":`loadedwords`})
           
       }}>Search for a loaded term</a> 
									</div>

		 						</div>
		 					</div>	
                            {/* <!-- END FEATURE BOX #4 -->	 */}


		 					{/* <!-- FEATURE BOX #5 --> */}
		 					<div class="col">
		 						<div class="fbox-2 fb-5 wow animate__animated animate__fadeInUp animate__delay-1">

		 						

									{/* <!-- Text --> */}
									<div class="fbox-txt">
										<h5>Access critical insights</h5>
										<p>That will help you see and think better.
										</p>
                                        <a style={{'margin-right': '5px'}}href='/insights' class="btn mt-1 btn-md r-36 btn--tra-black hover--black" onClick={() =>
          {
            insertEvent('click',{'button':'insights',"objectName":`insights`})
           
       }}>Get Clarity</a> 
									</div>

		 						</div>
		 					</div>	
                            {/* <!-- END FEATURE BOX #5 -->	 */}


		 					


						</div> 
                         {/* <!-- End row -->   */}
					</div>	
                    {/* <!-- END FEATURES WRAPPER --> */}


				</div> 


</>


        
  )
}

export default AppNavigate2