import React from 'react'

const Christians = () => {
  return (

<div class="container">

            <div class="">

                {/* <!-- Title --> */}
                <h4 class="h4-md">Our Work is Backed by Biblical Doctrine</h4>

                <p class="h4-md color--black">
                Unless stated otherwise, all anxiety related content and solutions On Clarity are based on biblical principles. Clarity will provide you the psychological tips, tricks, and tools so you can see and repeatedly remind yourself in your toughest moments why and how our creator doesn't want you to fear. 

                We believe that the teaching of Jesus & the Bible can help all humanity so although everything on Clarity is Biblically backed, its open to the public. 
                {/* 								<!-- We hope  you will respect that Clarity is trying to address the mass consciousness of anxiety in  -->
 */}
                </p>


            </div>

                {/* FAQ component */}
                  <section id="preview" class="py-10 mb-3 faqs-3 faqs-section division">				
                            <div class="container">
                            <h4 class="h4-md">What the Bible says about anxiety</h4>

                                <div class="row">
                      {/* <!-- FAQs ACCORDION --> */}
                                    <div class="col-8">
                          
                                        <div class="accordion-wrapper">
                                            <ul class="accordion">
            
            
                                                {/* <!-- QUESTION #1 --> */}
                                                <li class="accordion-item  wow animate__animated animate__fadeInUp">
            
                                                    {/* <!-- Question --> */}
                                                    <div class="accordion-thumb">
                                                        <h5>Mathew 10:28 </h5>
                                                    </div>
            
                                                    {/* <!-- Answer --> */}
                                                    <div class="accordion-panel">
            
                                                        {/* <!-- Text -->	 */}
                                                        <p>Jesus said, “Do not fear those who kill the body, but are unable to kill the soul”

Mathew 10:28 
                                                        </p>
            
                                                    </div>
            
                                                </li>	
                              {/* <!-- END QUESTION #1 --> */}

                                                 {/* <!-- QUESTION #2 --> */}
                                                 <li class="accordion-item  wow animate__animated animate__fadeInUp">
            
                                                            {/* <!-- Question --> */}
                                                            <div class="accordion-thumb">
                                                                <h5>1  Peter 3:14 -15</h5>
                                                            </div>

                                                            {/* <!-- Answer --> */}
                                                            <div class="accordion-panel">

                                                                {/* <!-- Text -->	 */}
                                                                <p>Peter said, “Do not fear their intimidation, and do not be troubled, but sanctify Christ as Lord in your hearts, always being ready to make a defense to everyone who asks you to give an account for the hope that is in you, yet with gentleness and reverence”
                                                                </p>

                                                            </div>

                                                        </li>	
                                                {/* <!-- END QUESTION #2 --> */}
                                                 {/* <!-- QUESTION #2 --> */}
                                                 <li class="accordion-item  wow animate__animated animate__fadeInUp">
            
                                                            {/* <!-- Question --> */}
                                                            <div class="accordion-thumb">
                                                                <h5> Proverbs 9:10 
                                                                </h5>
                                                            </div>

                                                            {/* <!-- Answer --> */}
                                                            <div class="accordion-panel">

                                                                {/* <!-- Text -->	 */}
                                                                <p>“We are to worship and fear God. Fear of any object or personality other than God is mutually exclusive to faith in God.” (1)<br></br>

The Bible says, “The fear of the LORD is the beginning of wisdom”

                                                                </p>

                                                            </div>

                                                        </li>	
                                                {/* <!-- END QUESTION #2 --> */}
                                                 {/* <!-- QUESTION #2 --> */}
                                                 <li class="accordion-item  wow animate__animated animate__fadeInUp">
            
                                                            {/* <!-- Question --> */}
                                                            <div class="accordion-thumb">
                                                                <h5>Isaiah 8: 12-13
                                                                </h5>
                                                            </div>

                                                            {/* <!-- Answer --> */}
                                                            <div class="accordion-panel">

                                                                {/* <!-- Text -->	 */}
                                                                <p>Do not call conspiracy everything this people calls a conspiracy;
do not fear what they fear,
and do not dread it.
<br></br>
The Lord Almighty is the one you are to regard as holy,
he is the one you are to fear, he is the one you are to dread.
                                                                </p>

                                                            </div>

                                                        </li>	
                                                {/* <!-- END QUESTION #2 --> */}
                                                 {/* <!-- QUESTION #2 --> */}
                                                 <li class="accordion-item  wow animate__animated animate__fadeInUp">
            
                                                            {/* <!-- Question --> */}
                                                            <div class="accordion-thumb">
                                                                <h5>1 Peter 5:7
                                                                </h5>
                                                            </div>

                                                            {/* <!-- Answer --> */}
                                                            <div class="accordion-panel">

                                                                {/* <!-- Text -->	 */}
                                                                <p>Cast all your anxiety on him because he cares for you.
                                                                </p>

                                                            </div>

                                                        </li>	
                                                {/* <!-- END QUESTION #2 --> */}

                              
            
            
                                            </ul>
                                        </div>
                                    </div>	
                        {/* <!-- END FAQs ACCORDION -->	 */}
                        </div>     
                        {/* <!-- End row --> */}
                            </div>	   
                    {/* <!-- End container --> */}
                        </section>	
                  {/* <!-- END FAQs --> */}
       
</div>  

  )
}

export default Christians